@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* -ms-overflow-style: none; IE and Edge */
}

/* @-moz-document url-prefix() {
  html,
  body {
    scrollbar-width: none; /* Firefox */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiListItem-button.active {
  background-color: #89c400;
}
div {
  -webkit-tap-highlight-color: transparent;
}

a {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.Toastify__toast--success {
  background: #89c400 !important;
}

/* body::-webkit-scrollbar {
  display: none;
} */

/* * { 
    outline: 1px solid red;
    outline-offset: -1px;
  } */
